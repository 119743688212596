<template>
    <v-container class="content" no-gutters>
        <!-- FAQ TITLE-->
        <v-row align="center" justify="center" class="mt-8 mt-lg-12 mx-8 mx-lg-0">
            <p
                :style="{'color': items[0]['colorTitle']}"
                class="customTitle"
                id="faqTitle"
            >
                {{ items[0]['titleScreen'] }}
            </p>
        </v-row>

        <!--FAQ content-->
        <v-row align="center" justify="center" class="pa-8">
            <FaqList></FaqList>
        </v-row>

        <!-- CTA BUTTON -->
        <v-row align="center" justify="center" class="mb-16">
            <v-col cols="12" align="center">
                <Button
                    class="mb-lg-16"
                    :name="items[0]['buttonText']"
                    :color="btnText[0]['color4']"
                    :w="btnText[0]['w3']"
                    :usage-function="goToContactScreen"
                ></Button>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import * as db from "./../../constants/faq_database"
import {home_btn} from "./../../constants/home_database"
import FaqList from "@/components/faq/Faq_List"
import Button from '@/components/buttons/Button.vue'

export default {
    name: "FaqScreen",
    components: {
        FaqList,
        Button
    },
    data() {
        return {
            items: null,
            btnText: null,
        }
    },
    computed: {
        //Gets current language
        language() {
            return this.$store.state.language
        }
    },
    beforeMount() {
        if (this.language === 'es') {
            /// Import faq info
            this.items = db.faq_info_es;
            //import home database
            this.btnText = home_btn;
        } else {
            /// Import faq info
            this.items = db.faq_info_en;
            //import home database
            this.btnText = home_btn;
        }

    },
    methods: {
        goToContactScreen: function () {
            this.$router.push('/contact')
        }
    }
}
</script>

<style scoped>
#faqTitle {
    text-align: center;
}
</style>