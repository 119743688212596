<template>
    <!-- Structure of answered question (right image)-->
    <v-container class="no-gutters pa-0 ma-0 px-2 px-lg-6 mb-8">
        <v-row no-gutters dense align="center" justify="center" class="">
            <v-col cols="12" md="8" sm="8" class="px-0 px-lg-8">
                <!-- QUESTION -->
                <p :style="{'color': items[0]['colorTitle']}" class="questionSubtitle">{{ question }} </p>
                <!-- ANSWER -->
                <p :style="{'color': items[0]['colorText']}" class="customText pa-0 ma-0">{{ answer }}</p>
            </v-col>

            <!-- FAQ ICON FOR DESKTOP -->
            <v-col cols="4" class="px-16" align-self="center" align="center">
                <div class="hidden-sm-and-down">
                    <v-icon style="font-size: 6em;" dark color="java">
                        {{ icon }}
                    </v-icon>
                </div>
            </v-col>
        </v-row>

        <!-- FAQ ICON FOR MOBILE -->
        <v-row no-gutters dense align="center" justify="center" class="mt-6 hidden-md-and-up px-16">
            <v-icon style="font-size: 4.5em;" dark color="java">
                {{ icon }}
            </v-icon>
        </v-row>

    </v-container>
</template>

<script>
import {faq_info_es} from "@/constants/faq_database"

export default {
    name: "FaqContent",
    props: {
        question: String,
        answer: String,
        icon: String
    },
    data() {
        return {
            items: null
        }
    },
    beforeMount() {
        // Import faq info
        this.items = faq_info_es;
    }
}
</script>

<style scoped>
</style>