export const faq_info_es = [
    {
        titleScreen: "Preguntas frecuentes",
        buttonText: "¿TIENES MÁS DUDAS?",
        colorTitle: "#16CCCC",
        colorText: "#808080"
    }
]

export const questions_info_es = [
    {
        title: "¿Cuánto tiempo toma desarrollar una app?",
        answer: "Es una pregunta difícil y que no tiene una respuesta que aplique para todos los casos. Nos hemos encontrado con proyectos que nos toman 3-4 semanas pero también hemos llevado a cabo algunos que toman más de 12 meses. Para poder dar una respuesta que aplique para tu proyecto, necesitamos evaluar la complejidad y el alcance de este, una vez que tengamos esta información podremos darte un tiempo exacto para desarrollar tu proyecto. Te invitamos a ponerte en contacto con nuestro equipo para poder realizar una propuesta de trabajo para tu solicitud.",
        icon: "schedule"
    },
    {
        title: "¿Existen costos por subir una app a la AppStore y PlayStore?",
        answer: "Para que una empresa o persona pueda subir apps a los marketplaces necesita crear una cuenta como propietarios de sus apps. Para la Apple AppStore existe una cuota anual de $99 USD que te permite subir todas las apps que tu empresa desarrolle. Hablando de la Google PlayStore existe una cuota única de $25 USD que te permite desplegar todas las apps para dispositivos Android que quieras desarrollar.",
        icon: "money_off"
    },
    {
        title: "¿Necesito saber mucho de tecnología para administrar mi app?",
        answer: "Afortunadamente no, en el equipo de ideen buscamos la forma de dar solución a tu necesidad de la manera más simple posible, así tú te enfocas en tu modelo de negocios y otros temas, mientras nosotros nos encargamos por completo de todas las soluciones técnicas que tu aplicación requiera.",
        icon: "tips_and_updates"
    },
    {
        title: "¿Qué tan caro puede ser el desarrollo de una app?",
        answer: "Cada proyecto de desarrollo de software es totalmente distinto, tienen diferentes necesidades, alcances, audiencias, deadlines, mercados, etc. Motivo por el cual necesitamos conocer tu idea y/o problema a resolver para poder realizar una propuesta económica y de trabajo para tu requerimiento. Pero tampoco te mortifiques, siempre buscamos la manera de desarrollar un producto de alta calidad que se adapte a tu presupuesto.",
        icon: "savings"
    },
    {
        title: "¿Cómo decido si empiezo por lanzar una app para Android o para iOS?",
        answer: "Cada una de las plataformas tiene sus ventajas y desventajas, siempre hay puntos a comparar entre estas hablando de temas como rendimiento, velocidad de desarrollo, compras dentro de app, revisiones de actualizaciones, estándares de calidad, etc. Pero seamos honestos, cuando tu escuchas que hay una nueva app asumes que esta existe para Android y para iOS, entonces ¿Por qué tendría que estar pensando en decidirme solo por una opción? Afortunadamente hoy en día existen tecnologías de desarrollo que nos permiten crear aplicaciones que, con una sola base de código y ligeras diferencias de configuración podemos crear la misma app para Android y iOS. Esta es una solución perfecta para nuevas aplicaciones y emprendedores que buscan validar sus ideas con usuarios reales.",
        icon: "android"
    },
    {
        title: "¿Cómo puedo monetizar con mi app?",
        answer: "Existen múltiples modelos de ingresos que puedes implementar en tu app. En caso de que tu producto o servicio sea físico puedes cobrarlos desde tu app a través de diferentes pasarelas de pago de terceros como PayPal, Mercado Pago, Stripe, etc. ¿Pero qué pasa si tu producto es un bien o servicio digital? Los marketplaces (AppStore & PlatStore) obligan a las apps que vendan este tipo de productos a utilizar su propio API para realizar los cobros de ventas. Productos como suscripciones, accesorios para juegos, servicios de streaming, consultas vía videollamadas, tokens para juegos o actividades, entre otros, son aquellos que entran en esta regla de emplear su API para los cobros, en caso de no querer usarlo, te rechazarán el despliegue de tu app.",
        icon: "payments"
    },
    {
        title: "¿Cómo solicito una cotización para una app?",
        answer: "Te invitamos a ir a nuestra pestaña de 'Contacto' para que te comuniques con nosotros por el medio que prefieras. Un ejecutivo de cuenta se pondrá en contacto contigo y solicitaremos una reunión para que nos platiques a detalles tu necesidad para poder crear una propuesta económica y de trabajo.",
        icon: "price_check"
    },
    {
        title: "¿Cómo sé si debo hacer una web app o una mobile app?",
        answer: "Ambas opciones tienen sus pros y contras, las aplicaciones móviles son un poco más caras y su proceso de despliegue es mucho más lento, pero tienen mucho mejores capacidades e implementaciones de monetización y son las que más confianza inspiran a los usuarios. Por otro lado, las aplicaciones tienen una versatilidad inmensa, menos restricciones de modelos de negocios y un proceso de despliegue prácticamente inmediato. Aun así recuerda que en cuanto tu aplicación tenga el éxito que buscas es muy probable que termines necesitando aplicaciones móviles y web para ofrecer tus servicios, en cualquier etapa de tu proyecto, estamos listos para ayudarte.",
        icon: "devices"
    },
    {
        title: "Si decido trabajar con ideen, ¿puedo ver avances de mi proyecto?",
        answer: "¡Claro! Nos gusta tener mucha comunicación y apertura con nuestros clientes. En todas las etapas de desarrollo de cualquier producto estarás involucrado, pendiente y tranquilo de que estamos creando una herramienta que cumplirá con tus expectativas.",
        icon: "update"
    },
    {
        title: "¿Cómo puedo vender más en mi e-commerce?",
        answer: "Existen muchas maneras de optimizar un e-commerce para incrementar las ventas y no todas aplican para todos los casos pero las prácticas más simples que puedes ayudar a mejorar son: Facilitar el check-out, implementar varios métodos de pago, asegurarte de tener una estructura simple en tu catálogo, tener SSL, tener datos de contacto siempre a la mano para resolver dudas, implementar campañas de marketing pautadas. Todas estas prácticas son nuestra especialidad, contáctanos para platicar de tu necesidad y ver la manera de trabajar en equipo.",
        icon: "storefront"
    },
    {
        title: "¿Para qué contratar una agencia de marketing digital si cualquiera puede invertir en pauta en Facebook?",
        answer: "ideen como agencia de marketing digital tiene la formación adecuada, experiencia, actualizaciones de tecnología y dedicación que se necesita para planear y ejecutar campañas de marketing digital en múltiples canales que generaran resultados a corto, mediano y largo plazo con base en tus objetivos.",
        icon: "auto_fix_high"
    },
    {
        title: "¿Cuándo empezaré a obtener conversiones en mi campaña?",
        answer: "El marketing digital (así como el tradicional) no consigue resultados inmediatos, todo depende de la estrategia del proyecto. Hemos implementado muchos proyectos en los que los resultados comienzan a tener impacto real en diferentes lapsos de tiempo, pero lo que sí podemos garantizar es un crecimiento continuo del impacto que genera tu marca sobre la audiencia.",
        icon: "ads_click"
    },
    {
        title: "¿Qué es el SEO?",
        answer: "Es un conjunto de prácticas que optimiza el posicionamiento de un sitio web en los resultados de búsqueda en Google y otros motores de navegación de internet.",
        icon: "vertical_align_top"
    },
    {
        title: "¿Qué pasa que si requiero un servicio de desarrollo o marketing que no encontré en su página?",
        answer: "Te invitamos a ir a nuestra pestaña de 'Contacto' para que te comuniques con nosotros por el medio que prefieras. Un ejecutivo de cuenta se pondrá en contacto contigo y solicitaremos una reunión para que nos platiques a detalle tu necesidad para poder crear una propuesta económica y de trabajo.",
        icon: "error"
    },
    {
        title: "¿Puedo contratar un servicio de creación de website o e-commerce y conectarlo a un dominio que ya tengo?",
        answer: "¡Por supuesto que sí! Podemos utilizar cualquier dominio que tengas previamente adquirido sin importar el proveedor (GoDaddy, Hostinger, Google Domains, etc.), únicamente te solicitaremos acceso al panel de control de tu dominio para poder realizar las configuraciones para el lanzamiento de tu nuevo e-commerce, website o web app.",
        icon: "travel_explore"
    },
    {
        title: "¿Qué es el certificado de seguridad SSL?",
        answer: "SSL es el acrónimo de Secure Sockets Layer y es el protocolo de seguridad que nos permite generar conexiones seguras a través de HTTPS. Este comunica a los visitantes de un sitio web que el website que visitan es seguro y ha sido confirmado que la empresa o persona que dice ser dueñ@ de dicho sitio, en realidad lo es. Hoy en día es un requisito indispensable tener certificado SSL en tu sitio web, ya que muchos navegadores modernos no permiten a los usuarios ingresar a un website si no tiene el certificado SSL.",
        icon: "https"
    },
]

export const faq_info_en = [
    {
        titleScreen: "Frequently asked questions",
        buttonText: "NEED MORE HELP?",
        colorTitle: "#16CCCC",
        colorText: "#808080"
    }
]

export const questions_info_en = [
    {
        title: "How long does it take to develop an app?",
        answer: "It's a tricky question as it doesn't have an answer that applies to all cases. We have built projects" +
          "that took us 3-4 weeks, but we have also others that took more than 12 months. To be able to give a more" +
          " accurate answer for your project we would need to evaluate its complexity, once we have this information " +
          "we will be able to do a time estimation for developing it. We encourage you to contact our team so we will " +
          "be able to create a work, economic and time proposal for your request.",
        icon: "schedule"
    },
    {
        title: "Is there any fee required to be paid to be able to deploy an app on Google Play Store or Apple App Store?",
        answer: "Everytime a company or an individual wants to deploy an app to Google & Apple marketplaces, they need " +
          "to create an account to be able to do it. For iOS apps there is an anual $99 USD fee ($299 for companies)" +
          " to be paid to be able to share all the apps you or your company creates on Apple App Store. For Android " +
          "apps there is a unique fee of $25 USD that allows any person or company to deploy multiple apps on Google " +
          "Play Store.",
        icon: "money_off"
    },
    {
        title: "Do I need to be a tech guru tu have and manage an app?",
        answer: "Fortunately, no. ideen's team work is to simplify your technical operation, so you can focus on your " +
          "business model and related topics while we manage all the software operations.",
        icon: "tips_and_updates"
    },
    {
        title: "How expensive can software development be?",
        answer: "Each software development project is unique with different scope, audiences, requirements, deadlines " +
          "and industries. To be able to create an economic proposal for your project we would need to get to know your " +
          "specific needs. But don't worry about it, we always try to find a way to create high quality software pieces " +
          "that match your budget.",
        icon: "savings"
    },
    {
        title: "How should I prioritize to develop an app for Android or iOS?",
        answer: "Each platform has their pros and cons, there are always differences between performance, reach, " +
          "development speed, in app purchases capabilities, deployment processes, etc. But let's be honest, when you " +
          "hear about a new app, in 2023, you assume it is available on Android & iOS, so why should I choose only one " +
          "platform? Fortunately, nowadays, there are plenty of languages and frameworks that allow us to create multi " +
          "platform apps with a single code base, so you can deploy your app for iOS & Android at the same time. This " +
          "kind of tools work perfectly with new apps and entrepreneurs who want to deliver a completely useful product " +
          "as soon as possible.",
        icon: "android"
    },
    {
        title: "¿Which are the monetization possibilities on mobile apps?",
        answer: "There exists multiple ways to monetize through an app. In case you want to offer a physical service " +
          "or product you can request for payment through any payment gateway you like such as PayPal, Mercado Pago, " +
          "Stripe, etc. But what should you do if you want to offer a digital service or product? App Store and Play " +
          "Store requires you to use their in-app purchases gateway to charge for this kind of products and services. " +
          "Subscriptions, games add-ons, streaming services, communication services, tokens ot game coins among other " +
          "products and services are the one who need to be purchased through the In-App Purchase API for each platform.",
        icon: "payments"
    },
    {
        title: "How can I request for a quote for an app idea I have?",
        answer: "We encourage you to go to out 'Contact' tab on top of this website so just can contact us through " +
          "your preferred channel. A sales executive will call you back and he/she will request to schedule a short " +
          "appointment to let us get to know more details about your requirements, so we can create a proposal for your" +
          "project.",
    },
    {
        title: "How should I choose between developing for mobile or web?",
        answer: "Both platforms are too popular and powerful while they have some trade ofs. Mobile apps are slightly more " +
          "expensive and the deployment process takes longer, but they have greater capabilities, can use in-app " +
          "purchases and are the one who inspire more security to users. On the other hand, web apps are more versatile, " +
          "have fewer restrictions and the deployment process can be nearly immediately.",
        icon: "devices"
    },
    {
        title: "If I choose to work with ideen, can I see how my project is progressing?",
        answer: "For sure! We like to have continuous and open communication with our clients. During the different" +
          " steps on software development life cycle you will be involved, notified and calm that your investment is " +
          "going to deliver the product you expected.",
        icon: "update"
    },
    {
        title: "How can I increase sales on my e-commerce?",
        answer: "There are plenty of ways to optimize an e-commerce website to increase their sales and not every " +
          "technique is useful for any store. But if we had to list to best practices to improve your e-commerce " +
          "performance, these are: Have an easy check-out process, have a simple structure on your catalogue, have SSL, " +
          "have contact data available with ease to support customers and do some paid marketing campaigns. All of the " +
          "practices mentioned before are some of our strengths, contact us to find a way we can help you grow your " +
          "business",
        icon: "storefront"
    },
    {
        title: "Why would I need help for a marketing campaign with an agency if everybody can manage social media tools?",
        answer: "As a marketing team, ideen, has more than required experience, technology, formation and dedication " +
          " to plan and execute multichannel digital marketing campaigns that create impact on short, medium " +
          "and long term based on your objectives and needs.",
        icon: "auto_fix_high"
    },
    {
        title: "How early can I expect to see results on a marketing campaign?",
        answer: "Digital marketing (as traditional one) does not get immediate results, it all depends on project " +
          "strategy. We have worked on projects in which results start to come on different time lapses, the only thing " +
          "we can assure is a continuous growth on your digital footprint towards your audience.",
        icon: "ads_click"
    },
    {
        title: "What does SEO mean?",
        answer: "Casually explained, SEO are a set of practices that improve your website position on search results on" +
          "Google and other search engines.",
        icon: "vertical_align_top"
    },
    {
        title: "What should I do if I need a marketing or software development service that is not on ideen's website?",
        answer: "We encourage you to go to out 'Contact' tab on top of this website so just can contact us through " +
          "your preferred channel. A sales executive will call you back and he/she will request to schedule a short " +
          "appointment to let us get to know more details about your requirements, so we can analyze if we are helpful " +
          "and create a proposal for your project.",
        icon: "error"
    },
    {
        title: "Can I acquire an e-commerce development service with ideen and connect it with an existing domain?",
        answer: "For sure! We can use any previously purchased domain no matter with which domains provider you " +
          "purchased it (GoDaddy, Hostinger, Google Domains, AWS, etc.), we are going to need access to your control " +
          "panel of your domain provider so we can adjust the settings of your domain and connect it with your new " +
          "e-commerce or website.",
        icon: "travel_explore"
    },
    {
        title: "What does SSL mean?",
        answer: "SSL is the acronym for Secure Sockets Layer, it is a security protocol that allows us to generate " +
          "secure connections through internet (HTTPS Protocol). It assures to website visitors that the website they " +
          "are in is secure and that it is a legitimate website. Nowadays, SSL is a non-negotiable when deploying " +
          "anything on internet as most browsers do not allow users to visit websites which don't have SSL certificate.",
        icon: "https"
    },
]