<template>
    <v-row>
        <v-col>
            <v-responsive>
                <!-- Display all questions available in the database-->
                <v-container v-for="(item, index) in questions" v-bind:key="item + '-' + index">
                    <!-- Change use of component depending on the number(position) of questions in the database.
                         pair=image at the right (use of "FaqContent component")
                         odd=image at the left (use of "FaqContentAlternate" component)-->
                    <FaqContent
                        v-if="index % 2 === 0"
                        :question="questions[index]['title']"
                        :answer="questions[index]['answer']"
                        :icon="questions[index]['icon']"
                    ></FaqContent>
                    <FaqContentAlternate
                        v-else
                        :question="questions[index]['title']"
                        :answer="questions[index]['answer']"
                        :icon="questions[index]['icon']"
                    ></FaqContentAlternate>

                </v-container>
            </v-responsive>
        </v-col>
    </v-row>
</template>

<script>
import * as db from "@/constants/faq_database"
import FaqContent from "@/components/faq/Faq_Content.vue"
import FaqContentAlternate from "@/components/faq/Faq_ContentAlternate.vue"

export default {
    name: "FaqList",
    components: {
        FaqContent,
        FaqContentAlternate
    },
    data() {
        return {
            questions: []
        }
    },
    computed: {
        //Gets current language
        language() {
            return this.$store.state.language
        }
    },
    mounted() {
        this.getQuestions()
    },
    methods: {
        getQuestions() {
            if (this.language === 'es') {
                this.questions = db.questions_info_es;
            } else {
                this.questions = db.questions_info_en;
            }
        }
    },
}
</script>

<style scoped>

</style>